declare var CryptoJS: any;

export class SyncStorageManager {

    slogan = 'wearerockstar';

    public convertData(data: any) {
      if (data === "" || data == undefined || data == null) {
        return "";
      } else {
        return CryptoJS.AES.encrypt(data, this.slogan).toString();
      }
    }

    public fetchData(data: any) {
      if (data === "" || data == undefined || data == null) {
        return "";
      } else {
        return CryptoJS.AES.decrypt(data, this.slogan).toString(CryptoJS.enc.Utf8);
      }
    }

    getLocalData() {
      if (!localStorage.rev) {
        return;
      }
      return this.fetchData(JSON.parse(localStorage.rev));
    }

    public setLocalData(localData: any) {
      localData = JSON.stringify(this.convertData(JSON.stringify(localData)));
      localStorage.setItem('rev', localData)
    }

    public setLocalProperty(key: any , value: any) {
      if(value!=null && value!=undefined)
        localStorage.setItem(key, JSON.stringify(this.convertData(JSON.stringify(value))));
      else
        localStorage.setItem(key, JSON.stringify(this.convertData(JSON.stringify(""))));
        
    }

    public getLocalProperty(property: string) { 
      if (localStorage.getItem(property)  == null) {
        return;
      }
      if (this.fetchData(JSON.parse( localStorage.getItem(property) ) ) === '') {
        return false;
      } else {
        return JSON.parse(this.fetchData(JSON.parse(localStorage.getItem(property))));
      }
    }

    public setSessionProperty(key: any , value: any) {
      if(value!=null && value!=undefined)
        sessionStorage.setItem(key, JSON.stringify(this.convertData(JSON.stringify(value))));
      else
        sessionStorage.setItem(key, JSON.stringify(this.convertData(JSON.stringify(""))));
        
    }

    public getSessionProperty(property: string){
      if(sessionStorage.getItem(property) == null){
        return
      }
      if (this.fetchData(JSON.parse( sessionStorage.getItem(property) ) ) === '') {
        return false;
      } else {
        return JSON.parse(this.fetchData(JSON.parse(sessionStorage.getItem(property))));
      }
    } 
    
}