<div class="app-body lr-page d-flex flex-column pt-lg-0 pt-4">
  <main class="main d-flex flex-column justify-content-around">
    <div class="d-flex col-12 col-lg-6 offset-lg-6 pb-3 py-lg-4 logo-container">
      <img src="assets/img/brand/company-logo.png" alt="RevMapper" class="logo" />
    </div>
    <div class="container-fluid h-100">
      <div class="d-flex align-items-center flex-column flex-md-row justify-content-center people-bg">
        <div class="col-12 col-md-6  col-lg-5 effect5">
          <div class="card-group effect6 offset-0 offset-lg-3">
            <div class="card p-0 p-md-4">
              <div class="card-body px-0">
                <form>
                  <h1 class="pt-4 pt-md-0">Forgot Password</h1>
                  <p class="text-muted">Enter your details</p>
                  <div>
                    <label for="email">Email Address</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" #email name="email" id="email" class="form-control" placeholder="Email"
                        autocomplete="email" (keyup.enter)="forgotPassword(email)" required />
                    </div>
                  </div>

                  <div class="row">
                    <small><span *ngIf="isAnyError" class='error'> {{errorMessage}} </span></small>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="button" class="btn btn-primary px-4 w-100" id="submit-btn"
                        (click)="forgotPassword(email)">Submit</button>
                    </div>
                    <div class="col-12 text-center pt-3">
                      <button type="button" id="login-btn" class="btn btn-link px-0" (click)="login()">Login?</button>
                    </div>
                  </div>
                </form>
                <div class="col-12 text-center ">
                  <small id="register-text">Don't have an Account?</small>
                  <button type="button" class="btn btn-link" id="register-btn" (click)="register()">Sign Up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 d-none d-lg-flex"></div>
      </div>
    </div>
    <footer class="d-flex justify-content-around pt-5 mt-xl-5">
      <span><a href="https://www.revmapper.com" target="_blank">RevMapper</a> &copy; 2023.</span>
      <span class="">Powered by <a href="https://www.revmapper.com" target="_blank">RevMapper</a></span>
    </footer>
  </main>
</div>



<div id="forgot-password-message-modal" bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="forgotPasswordModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" (click)="infoModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="this.userMessage != undefined">{{this.userMessage}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="infoModal.hide();login();">Okay</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div id="spinner_loader">
  <div class="loader"></div>
</div>