import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse,
  HttpClient
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap, retry, delay, switchMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { SyncStorageManager } from '../core/database/SyncStorageManager';
declare var jQuery;

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  public headers: any;
  token: any;
  private router;
  private http;

  
  constructor(public globalService: GlobalService, private syncStorageManager: SyncStorageManager) {
     this.router = globalService.getRouterRef();
     this.http = globalService.getHttpClientRef();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if ((localStorage.userDetails && localStorage.authorizationToken)) {

        this.headers = {
          "Accept" : "application/json; charset=UTF-8",
          "Authorization" : "Bearer " + ((this.globalService.getUserDetails().token) ? this.globalService.getUserDetails().token : this.syncStorageManager.getLocalProperty("authorizationToken")),
          "Request-Id" :  this.globalService.create_UUID(),
          "X-TENANT" :  this.syncStorageManager.getLocalProperty("tenant")
        }
      } else if(window.location.href.indexOf('/reset-password')>0) {

        // var url_string = window.location.origin + window.location.hash.substring(1,window.location.hash.length);
        var url_string = window.location.href;
        var url = new URL(url_string);
        var token = url.searchParams.get('t');
        
        this.headers = {
          "Accept" : "application/json; charset=UTF-8",
          "Authorization" : "Bearer " + token,
          "Request-Id" :  this.globalService.create_UUID(),
        }
      } else {

        var hostname = window.location.hostname;
        var tenant = hostname.split('.')[0];
        
        // if(jQuery("#userId").val() == "admin") {
        //   tenant = "mithiaffiliates";
        // }

        // if(jQuery("#userId").val() == "demoadmin") {
        //   tenant = "demo";
        // }

        this.globalService.tenant = tenant;

        this.headers = {
          "Content-Type": "application/json",
          "Accept": "application/json; charset=UTF-8",
          "X-TENANT" :  tenant,
        }
      }

   

	   	request = request.clone({
	      	headers:new HttpHeaders(this.headers)
      });
      

      console.log("request intercepted");

	    return next.handle(request);
	}

}
