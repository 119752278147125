import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { ForgotpasswordComponent } from './views/forgotpassword/forgotpassword.component';
import { ResetPasswordComponent } from './views/user/reset-password/reset-password.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'forgotpassword',
    component: ForgotpasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },

  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    }
  },
  
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'brands',
        loadChildren: () => import('./views/brands/brands.module').then(m => m.BrandsModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule)
      },
      {
        path: 'partner',
        loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule)
      },
      {
        path: 'deals',
        loadChildren: () => import('./modules/deals/deals.module').then(m => m.DealsModule)
      },
      {
        path: 'configuration',
        loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'reporting',
        loadChildren: () => import('./modules/reporting/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'finance',
        loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'processing',
        loadChildren: () => import('./modules/processing/processing.module').then(m => m.ProcessingModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: false, relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
