export const environment = {
  production: true,
  baseUrl: window.location.origin,

  environmentsNames: {
    "https://mithiaffiliates.revmapper.com": "main", //AWS: http://igamism.com //PROD: https://mithiaffiliates.revmapper.com //STAGE: https://stage.hindisongs-lyric.com //DEMO: https://ui.revmapper.com
    "https://demo.revmapper.com": "main",
    //"http://52.209.139.56:8080": "demo",
    "https://mithiaffiliates.igamism.com": "demo",
    "https://demo.igamism.com": "demo",
    "https://vidaffiliates.igamism.com": "tenant1"
  },

  apiEndpoint:{
    main: { 
      "url": "https://mithimapper.click"//AWS: http://afflitrack.click:80 "PROD: https://mithimapper.click" //STAGE: https://afflitrack.click //DEMO: https://demo.revmapper.com
    },
    demo: {
      "url": "https://afflitrack.click"
    },
    tenant1: {
      "url": "https://vidmapper.click" //point to afflitrack.click
    }
  },

  apiUrls:{
    signup : "/v1/users",
    login : "/auth/logins",
    logout: "/logouts",
    baseUser : "/v1/users",
    forgotPassword : "/v1/users/forgotPassword",
    brands : "/v1/brands",
    campaigns : "/campaigns",
    landingPages : "/landingPages",
    tracks : "/v1/tracks",
    trackerLinks : "/v1/trackerLinks",
    bulkUser : "/v1/users/bulk",
    campaignsV1 : "/v1/campaigns",
    postbacks: "/v1/postbacks",
    rmpostback: "/v1/rm/postback",
    deals: "/v1/deals",
    configOptimizationTools: "/v1/configOptimizationTools",
    configEmailTemplates: "/v1/configEmailTemplates",
    configTermsAndConditions: "/v1/configTermsAndConditions",
    configNotifications: "/v1/configNotifications",
    dashboard: "/v1/dashboard",
    dashboardV2: "/v2/dashboard",
    siteid: "/sites",
    userpostbacks : "/postbacks",
    playerReport : "/v1/players/report",
    partnerReport : "/v1/partners/report",
    accountsReport : "/v1/accounts/report",
    siteIdReport : "/v1/siteId/report",
    landingReport : "/v1/landingPages/report",
    referralUrlReport: "/v1/referralurl/report",
    countryReport:"/v1/country/report",
    partners : "/v1/partners",
    configPaymentSettings: "/v1/configPaymentSettings",
    generalProcessings: "/v1/generalProcessings",
    processing: "/v1/processing",
    referrals: "/referrals",
    snapshots: "/v1/snapshots",
    wmsSnapshots: "/v1/wmsSnapshots",
    skcSnapshots: "/v1/skcSnapshots",
    atcProccessing: "/v1/atc",
    refProccessing: "/v1/refComm",
    config: "/v1/config",
    getPartnerCPA: "/v1/partner/isCPANGRClubbed",
    updatePartnerCPA: "/v1/partner",
    brandStatsApiWithKey: "/brandStatsApiWithKey",
    brandSummary: "/brandSummary",
  }

};