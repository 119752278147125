export enum RBAC {
    ROLE_SUPER_ADMIN = 1,
    ROLE_ADMIN = 2,
    ROLE_PARTNER = 3,
    ROLE_ACC_MGR = 4
}

export const ROLE = [
    {
        name : 'Account Manager',
        value: RBAC.ROLE_ACC_MGR
    }
]

export enum STATE_ACTION {
    STATUS_APPROVE = "Approve",
    STATUS_SUSPEND = "Suspend",
    STATUS_REJECT = "Reject",
    STATUS_DELETE = "Delete"
}

export enum STATE {
    STATE_PENDING = "PENDING",
    STATE_ACTIVE = "ACTIVE",
    STATE_SUSPEND = "SUSPEND",
    STATE_REJECT = "REJECT"
}

export const BRAND_TYPE = [
    "Casino", 
    "Sportsbook", 
    "Bingo", 
    "Poker", 
    "Others"
]

export const CAMPAIGN_TYPE = [
    "User Acquisition",
    "Partner Referral"
]

export const LANDING_PAGE_TABS = [
    {
        id : 'landing-pages',
        name : 'Landing Pages',
        icon : 'icon-people'
    },
    {
        id : 'campaign-resources',
        name : 'Campaign Resources',
        icon : 'icon-globe'
    }
]

export const USER_PAGE_TABS = [
    {
        id : 'user-details',
        name : 'Account',
        icon : 'icon-people'
    }
]

export const PARTNER_PAGE_TABS = [
    {
        id : 'partner-details',
        name : 'Account',
        icon : 'icon-people'
    },
    {
        id : 'partner-site-id',
        name : 'Site ID',
        icon : 'icon-globe'
    },
    {
        id : 'partner-deals',
        name : 'Deals',
        icon : 'fa fa-dollar'
    },
    {
        id : 'partner-postback',
        name : 'Postback URL',
        icon : 'icon-globe'
    },
    {
        id : 'partner-api-key',
        name : 'API Key',
        icon : 'icon-key'
    }
]

export const PARTNER_VIEW_PAGE_TABS = [
    {
        id : 'partner-details',
        name : 'Account',
        icon : 'icon-people'
    },
    {
        id : 'partner-site-id',
        name : 'Site ID',
        icon : 'icon-globe'
    },
    {
        id : 'partner-deals',
        name : 'Deals',
        icon : 'fa fa-dollar'
    }
]

export const CONFIGURATION_PAGE_TABS = [
    {
        id : 'postback-tab',
        name : 'Inward Postback',
        icon : 'icon-people'
    },
    {
        id : 'tracking-domains',
        name : 'Tracking Domains',
        icon : 'icon-globe'
    },
    {
        id : 'optimization-tool',
        name : 'Optimization Tool',
        icon : 'icon-globe'
    },
    {
        id : 'terms-and-conditions',
        name : 'Terms & Conditions',
        icon : 'icon-globe'
    },
    {
        id : 'email-templates',
        name : 'Email Templates',
        icon : 'icon-globe'
    },
    {
        id : 'notifications',
        name : 'Notifications',
        icon : 'icon-globe'
    },
    {
        id : 'paymentsettings',
        name : 'Payment Settings',
        icon : 'icon-globe'
    },
    {
        id : 'defaultsettings',
        name : 'Deal Settings',
        icon : 'icon-globe'
    }
]

export const CONFIGURATION_PAGE_TABS_ACCOUNT_MANAGER = [
    {
        id : 'postback-tab',
        name : 'Registration Postback',
        icon : 'icon-people'
    },
    {
        id : 'tracking-domains',
        name : 'Tracking Domains',
        icon : 'icon-globe'
    },
    {
        id : 'terms-and-conditions',
        name : 'Terms & Conditions',
        icon : 'icon-globe'
    },
    {
        id : 'email-templates',
        name : 'Email Templates',
        icon : 'icon-globe'
    },
    {
        id : 'notifications',
        name : 'Notifications',
        icon : 'icon-globe'
    },
    {
        id : 'paymentsettings',
        name : 'Payment Settings',
        icon : 'icon-globe'
    },
    {
        id : 'defaultsettings',
        name : 'Deal Settings',
        icon : 'icon-globe'
    }
]

export const STATUS = [
    {
        name : "Enabled",
        value : true
    },
    {
        name : "Disabled",
        value : false
    }
]

export const PROCESSING_PAGE_TABS = [
    {
        id : 'snapshot-tab',
        name : 'Snapshot',
        icon : 'icon-book-open'
    },
    {
        id : 'general-tab',
        name : 'General',
        icon : 'icon-people'
    },
    {
        id : 'auto-process-tab',
        name : 'Auto Process',
        icon : 'icon-people'
    }
]

export const REPORTING_PAGE_TABS = [
    /*{
        id : 'account-tab',
        name : 'Account',
        icon : 'icon-people'
    },*/
    {
        id : 'partner-tab',
        name : 'Partner',
        icon : 'icon-globe'
    },
    {
        id : 'partner-siteid-tab',
        name : 'Partner Site Id',
        icon : 'icon-globe'
    },
    {
        id : 'player-tab',
        name : 'Player',
        icon : 'icon-globe'
    },
    {
        id : 'landing-page-tab',
        name : 'Landing Page',
        icon : 'icon-people'
    },
    {
        id : 'referral-tab',
        name : 'Referral Commission',
        icon : 'icon-people'
    },
    {
        id : 'referral-url-tab',
        name : 'Referral URL',
        icon : 'icon-people'
    },
    {
        id : 'country-report-tab',
        name : 'Country Report',
        icon : 'icon-globe'
    }
]

export const REPORTING_PAGE_TABS_PARTNER = [
    /*{
        id : 'account-tab',
        name : 'Account',
        icon : 'icon-people'
    },*/
    {
        id : 'partner-tab',
        name : 'Partner',
        icon : 'icon-globe'
    },
    {
        id : 'partner-siteid-tab',
        name : 'Partner Site Id',
        icon : 'icon-globe'
    },
    {
        id : 'player-tab',
        name : 'Player',
        icon : 'icon-globe'
    },
    {
        id : 'referral-tab',
        name : 'Referral',
        icon : 'icon-people'
    }
]

export const REPORT_PLAYER_GROUPING = [
    {
        id : 'player',
        name : 'Player'
    },
    {
        id : 'playerByDay',
        name : 'Player By Day'
    },
    {
        id : 'playerByMonth',
        name : 'Player By Month'
    }
]

export const REPORT_PARTNER_GROUPING = [
    {
        id : 'partner',
        name : 'Partner'
    },
    {
        id : 'partnerByDay',
        name : 'Partner By Day'
    },
    {
        id : 'partnerByMonth',
        name : 'Partner By Month'
    }
]

export const REPORT_PARTNER_SITE_ID_GROUPING = [
    {
        id : 'siteId',
        name : 'Site Id'
    },
    {
        id : 'siteIdByDay',
        name : 'Site Id By Day'
    },
    {
        id : 'siteIdByMonth',
        name : 'Site Id By Month'
    }
]

export const REPORT_LANDING_PAGE_GROUPING = [
    {
        id : 'landingpage',
        name : 'Landing Page'
    },
    {
        id : 'landingpageByDay',
        name : 'Landing Page By Day'
    },
    {
        id : 'landingpageByMonth',
        name : 'Landing Page By Month'
    }
]

export const REPORT_ACCOUNT_GROUPING = [
    {
        id : 'account',
        name : 'Account'
    },
    {
        id : 'accountByDay',
        name : 'Account By Day'
    },
    {
        id : 'accountByMonth',
        name : 'Account By Month'
    }
]

export const DATE_RANGE_PICKER = [
    {
        id : 'today',
        name : 'Today'
    },
    {
        id : 'yesterday',
        name : 'Yesterday'
    },
    {
        id : 'last7days',
        name : 'Last 7 Days'
    },
    {
        id : 'last30days',
        name : 'Last 30 Days'
    },
    {
        id : 'thisMonth',
        name : 'This Month'
    },
    {
        id : 'lastMonth',
        name : 'Last Month'
    },
    {
        id : 'customRange',
        name : 'Custom Range'
    }
]

export const ALL_PARTNER_DATE_FINANCE_PICKER = [
    
    {
        id : 'thisMonth',
        name : 'This Month'
    },
    {
        id : 'lastMonth',
        name : 'Last Month'
    }
]

export const A_AM_DATE_FINANCE_PICKER = [
    {
        id: 'thisMonth',
        name: 'This Month'
    },
    {
        id: 'lastMonth',
        name: 'Last Month'
    },
    {
        id: 'last3Month',
        name: 'Last 3 Month'
    },
    {
        id: 'last6Month',
        name: 'Last 6 Month'
    },
    {
        id: 'last9Month',
        name: 'Last 9 Month'
    },
    {
        id: 'last12Month',
        name: 'Last 12 Month'
    }
]

export const DATE_FINANCE_PICKER = [
  
    {
        id : 'last3Month',
        name : 'Last 3 Month'
    },
    {
        id : 'last6Month',
        name : 'Last 6 Month'
    },
    {
        id : 'last9Month',
        name : 'Last 9 Month'
    },
    {
        id : 'last12Month',
        name : 'Last 12 Month'
    }
]



export const DATE_RANGE_PICKER_PROCESSING = [
    {
        id : 'yesterday',
        name : 'Yesterday'
    },
    {
        id : 'today',
        name : 'Today'
    }
]


export const TRACKING_TAG = [
    {
        id : 'progressPlay',
        name : 'Progress Play'
    },
    {
        id : 'nektan',
        name : 'Nektan'
    }
]

export const DATE_RANGE_PICKER_BRAND_SUMMARY_DASHBOARD = [
    {
        id : 'today',
        name : 'Today'
    },
    {
        id : 'yesterday',
        name : 'Yesterday'
    },
    {
        id : 'last7days',
        name : 'Last 7 Days'
    },
    {
        id : 'last30days',
        name : 'Last 30 Days'
    },
    {
        id : 'thisMonth',
        name : 'This Month'
    },
    {
        id : 'lastMonth',
        name : 'Last Month'
    },
    {
        id : 'last6Month',
        name : 'Last 6 Month'
    },
    {
        id : 'last9Month',
        name : 'Last 9 Month'
    },
    {
        id : 'last12Month',
        name : 'Last 12 Month'
    }
]