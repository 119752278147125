import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public http: any;
  emailTemplateId: any;
  notificationTemplateId: any;
  termsAndConditionsTemplateId: any;

  constructor(public globalService: GlobalService) { 
    this.http = this.globalService.getHttpClientRef();
    this.globalService = globalService;
  }

  setNotificationTemplateId(notificationTemplateId) {
    this.notificationTemplateId = notificationTemplateId;
  }

  getNotificationTemplateId() {
    return this.notificationTemplateId;
  }

  setEmailTemplateId(emailTemplateId) {
    this.emailTemplateId = emailTemplateId;
  }

  getEmailTemplateId() {
    return this.emailTemplateId;
  }

  setTermsAndConditionsTemplateId(termsAndConditionsTemplateId) {
    this.termsAndConditionsTemplateId = termsAndConditionsTemplateId;
  }

  getTermsAndConditionsTemplateId() {
    return this.termsAndConditionsTemplateId;
  }

  get(url) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.get(url).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }



  post(url, payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.post(url, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  put(url, payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.put(url, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  delete(url) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.delete(url).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
