import { Component, OnInit, ViewChild } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { GlobalService } from '../../../services/global.service';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var jQuery: any;

import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  userid: any;
  token: any;
  isAnyError: boolean = false;
  errorMessage: string;
  http: any;
  userMessage: any;

  @ViewChild('infoModal') public infoModal: ModalDirective;

  constructor(public globalService: GlobalService, private route: ActivatedRoute, private router: Router, private userService: UserService) {
    this.router = router;
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        if(params.uid != undefined) {
          this.userid = params.uid;
        }
        if(params.t != undefined) {
          this.token = params.t;
        }
      }
    );
  }

  onResetPassword(inputNewPassword, inputConfirmNewPassword) {
    console.log("on Reset Password Method");
    var classRef = this;

    this.isAnyError = false;
    this.errorMessage = "";

    if (!this.validateUserData(inputNewPassword.value, inputConfirmNewPassword.value)) {
      return;
    }

    let payload = {
      "newPassword": inputNewPassword.value
    }

    classRef.globalService.toggleSpinnerLoader(true);

    this.userService.resetPassword(payload, classRef.userid, classRef.token).then(function(response) {
      document.getElementsByTagName('form')[0].reset();
      classRef.userMessage = "New Password updated successfully";
      classRef.infoModal.show();
      classRef.globalService.toggleSpinnerLoader(false);
    }, function(error) {
      classRef.isAnyError = true;
      classRef.globalService.toggleSpinnerLoader(false);
      if(error.status && error.status == 401) {
        classRef.globalService.logout();
      } else if (error.status && error.status == 500) {
        classRef.errorMessage = "Something went wrong. Please try again later.";
      } else {
        classRef.errorMessage = error.error.message;
      }
    });
  }

  private validateUserData(inputNewPassword, inputConfirmNewPassword) {
    if (inputNewPassword == undefined || inputNewPassword == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter New Passoword";
      document.getElementById("inputNewPassword").focus();
      return false;
    } else if (inputConfirmNewPassword == undefined || inputConfirmNewPassword == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter Confirm New Passoword";
      document.getElementById("inputConfirmNewPassword").focus();
      return false;
    }
    if(inputNewPassword != inputConfirmNewPassword) {
      this.isAnyError = true;
      this.errorMessage = "Password Entered doesn't match";
      document.getElementById("inputConfirmNewPassword").focus();
      return false;
    }

    return true;
  }

  register() {
    this.router.navigate(['register']);
  }

  login() {
    this.router.navigate(['login']);
  }

}
