import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  public http: any;
  partnerId: any;
  siteId: any;
  postbackId: any;

  constructor(public globalService: GlobalService) { 
    this.http = this.globalService.getHttpClientRef();
    this.globalService = globalService;
  }

  getPartnerId() {
    return this.partnerId;
  }

  setPartnerId(partnerId) {
    this.partnerId = partnerId;
  }

  setPartnerSiteId(siteId) {
    this.siteId = siteId;
  }
  
  getPartnerSiteId() {
    return this.siteId;
  }  

  setPartnerPostbackId(postbackId) {
    this.postbackId = postbackId;
  }  

  getPartnerPostbackId() {
    return this.postbackId;
  }

  searchByUserId(payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + "filter";

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getProfile(userid) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + userid;

      classRef.http.get(serviceUrl).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateProfile(payload, userid) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + userid;

      classRef.http.put(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  changePassword(payload, userid, token) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + userid + "/changePassword";

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  resetPassword(payload, userid, token) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + userid + "/resetPassword";

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getAllUsers() {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"];

      classRef.http.get(serviceUrl).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getUsersByRole(role, orderBy, pageNo, pageSize, sortBy) {
    var classRef = this;

    var payload = {
      'role' : role + ""
    };

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + "filter" + "?orderBy=" + orderBy+ "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortBy=" + sortBy;

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }



  getPartnerById(partnerId) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + partnerId;

      classRef.http.get(serviceUrl).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateUserProfile(payload, userid) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + userid;

      classRef.http.put(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  registerUser(payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"];

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response); 
        },
        (error) => {
          reject(error);
        }
      );
    });
  }


  createUserByAdmin(payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/byAdmin";

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }



  bulkUpdateUserProfile(payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["bulkUser"];

      classRef.http.put(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  deleteUserProfile(payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"];

      classRef.http.delete(serviceUrl, { body: payload}).subscribe(
        (response) => {
         resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getUsersByStatus(payload, orderBy, pageNo, pageSize, sortBy) {
    var classRef = this;

    return new Promise(function(resolve, reject) {
      
      var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + "filter" + "?orderBy=" + orderBy+ "&pageNo=" + pageNo + "&pageSize=" + pageSize + "&sortBy=" + sortBy;

      classRef.http.post(serviceUrl, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  get(url) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.get(url).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }



  post(url, payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.post(url, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  put(url, payload) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.put(url, payload).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  delete(url) {
    var classRef = this;

    return new Promise(function(resolve, reject) {

      classRef.http.delete(url).subscribe(
        (response) => {
         resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

}
