import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
declare var jQuery: any;


import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  firstname: any;
  lastname: any;
  userid: any;
  email: any;
  password: any;
  companyname: any;
  websiteurl: any;
  isAnyError: any;
  errorMessage: any;
  registerMessage: any;
  passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  public http: any;
  refererId: any;
  passwordInputType: string = 'password';

  @ViewChild('infoModal') public infoModal: ModalDirective;
  @ViewChild('dangerModal') public tcModal: ModalDirective;

  termsAndConditionsList: any;
  htmlContent: any;
  passwordInfo: string = 'Check a password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.';

  constructor(public globalService: GlobalService, private router: Router, private route: ActivatedRoute, public configurationService: ConfigurationService) {
    this.http = this.globalService.getHttpClientRef();
    this.router = router;
    this.globalService.portalLogo = "assets/img/brand/" + this.globalService.tenant + "-logo.png";
  }

  ngOnInit() {
    if (this.globalService.getUserDetails() != undefined) {
      this.router.navigate(['dashboard']);
    }

    this.route.queryParams
      .subscribe(params => {
        // console.log(params);
        if (params.refererId != undefined) {
          this.refererId = params.refererId;
        }
      }
      );

    document.getElementById("firstname").focus();
  }

  login() {
    this.router.navigate(['login']);
  }

  validatePassword(passwordInput) {
    let password = passwordInput.value
    if (password == undefined || password == "") {
      this.isAnyError = true;
      this.errorMessage = "Password field cannot be blank";
      document.getElementById("password").focus();
      return false;
    } else if (!this.passwordRegex.test(password)) {
      this.isAnyError = true;
      this.errorMessage = this.passwordInfo;
      document.getElementById("password").focus();
      return false;
    } else {
      this.isAnyError = false;
      this.errorMessage = ''
      document.getElementById("password").focus();
      return false;
    }
  }

  validateEmail(email) {
    console.log(email.value)
    if (email.value && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email.value)) {
      this.isAnyError = true;
      this.errorMessage = "Enter valid email address";
      return false
    } else {
      this.isAnyError = false;
      return true;
    }
  }
  register(firstname, lastname, userid, email, password, companyname, websiteurl, invalidCheck) {
    var classRef = this;

    this.isAnyError = false;
    this.errorMessage = "";

    if (!this.validateLoginUserData(firstname.value, lastname.value, userid.value, email.value, password.value, companyname.value, websiteurl.value, invalidCheck.checked)) {
      return;
    }

    var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["signup"];

    let payload = {
      "firstName": firstname.value,
      "lastName": lastname.value,
      "userId": userid.value,
      "email": email.value,
      "password": password.value,
      "companyName": companyname.value,
      "websiteUrl": websiteurl.value,
      "invalidCheck": invalidCheck.value
    }

    if (this.refererId != "") {
      payload["refererId"] = this.refererId;
    }

    classRef.globalService.toggleSpinnerLoader(true);

    this.http.post(serviceUrl, payload, { observe: 'response' }).subscribe(
      (response) => {
        classRef.registerMessage = response;
        document.getElementsByTagName('form')[0].reset();
        this.infoModal.show();
        classRef.globalService.toggleSpinnerLoader(false);
      },
      (err) => {
        this.isAnyError = true;
        classRef.globalService.toggleSpinnerLoader(false);
        if (err.status && err.status == 500) {
          this.errorMessage = "Something went wrong. Please try again later.";
        } else {
          this.errorMessage = err.error.message;
        }
      }
    );
  }

  private validateLoginUserData(firstname, lastname, userid, email, password, companyname, websiteurl, invalidCheck) {
    if (firstname == undefined || firstname == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter valid First Name";
      document.getElementById("firstname").focus();
      return false;
    } else if (lastname == undefined || lastname == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter valid Last Name";
      document.getElementById("lastname").focus();
      return false;
    } else if (userid == undefined || userid == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter User Id";
      document.getElementById("userid").focus();
      return false;
    } else if (email == undefined || email == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter email Id";
      document.getElementById("email").focus();
      return false;
    } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      this.isAnyError = true;
      this.errorMessage = "Enter valid email address";
      document.getElementById("email").focus();
      return false;
    } else if (password == undefined || password == "") {
      this.isAnyError = true;
      this.errorMessage = "Password field cannot be blank";
      document.getElementById("password").focus();
      return false;
    } else if (!this.passwordRegex.test(password)) {
      this.isAnyError = true;
      this.errorMessage = this.passwordInfo;
      document.getElementById("password").focus();
      return false;
    } else if (companyname == undefined || companyname == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter Company Name";
      document.getElementById("companyName").focus();
      return false;
    } else if (websiteurl == undefined || websiteurl == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter website Url";
      document.getElementById("websiteurl").focus();
      return false;
    } else if (!invalidCheck) {
      this.isAnyError = true;
      this.errorMessage = "You must agree before submitting.";
      document.getElementById("invalidCheck").focus();
      return false;
    }

    return true;
  }

  getTermsAndConditions() {
    var classRef = this;
    var url = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["configTermsAndConditions"];
    this.configurationService.get(url).then(function (response: any) {
      // classRef.termsAndConditionsList = jQuery(response.data);
      jQuery("#tc-message-modal .modal-body").html(response.data.tc);
      classRef.tcModal.show();
    }, function (error) {
      classRef.isAnyError = true;
      if (error.status && error.status == 401) {
        classRef.globalService.logout();
      } else if (error.status && error.status == 500) {
        classRef.errorMessage = "Something went wrong. Please try again later.";
      } else {
        classRef.errorMessage = error.error.message;
      }
    });
  }

  showPassword(show: boolean) {
    console.log('hol')
    if (show) {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }


}
