import { Component, OnInit } from "@angular/core";
import { RBAC } from "../../core/enums/GlobalEnums";
import { GlobalService } from "../../services/global.service";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";
import { SyncStorageManager } from "../../core/database/SyncStorageManager";
import * as CryptoJS from "crypto-js";
declare var jQuery: any;

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component-2.html",
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  userId: any;
  password: any;
  isAnyError: any;
  errorMessage: any;
  public http: any;
  passwordInputType:string='password';
  constructor(
    public globalService: GlobalService,
    private router: Router,
    private syncStorageManager: SyncStorageManager) {
    this.http = this.globalService.getHttpClientRef();
    this.router = router;
    if(this.globalService.tenant != "") {
      this.globalService.portalLogo = "assets/img/brand/" + this.globalService.tenant + "-logo.png";
    } else {
        this.globalService.portalLogo = "assets/img/brand/default-logo.png";
    }
  }

  ngOnInit() {
    if (this.globalService.getUserDetails() != undefined) {
      this.router.navigate(["dashboard"]);
    }
    console.log(window.location.origin);
  }

  register() {
    this.router.navigate(["register"]);
  }

  forgotpassword() {
    this.router.navigate(["forgotpassword"]);
  }

  loginUser(userId, password) {
    console.log("Login User Method");
    var classRef = this;

    this.isAnyError = false;
    this.errorMessage = "";

    if (!this.validateLoginUserData(userId.value, password.value)) {
      return;
    }

    var serviceUrl =
      environment.apiEndpoint[
        environment.environmentsNames[environment.baseUrl]
      ]["url"] + environment.apiUrls["login"];

    let payload = {
      password: password.value, //this.passwordEncryptorUtil.encryptPassword(password.value)
    };

    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        userId.value
      )
    ) {
      payload["email"] = userId.value;
    } else {
      payload["userId"] = userId.value;
    }

    classRef.globalService.toggleSpinnerLoader(true);

    this.http.post(serviceUrl, payload, { observe: "response" }).subscribe(
      (response) => {
        let loginTime = new Date().getTime();
        window.localStorage.setItem("lastlogin", JSON.stringify(loginTime));
        classRef.globalService.setUserAuthToken(response.body.data.token);
        classRef.globalService.setUserDetails(response.body.data);
        classRef.globalService.setTenant(classRef.globalService.tenant);

        classRef.router.navigate(["dashboard"]);
        classRef.globalService.toggleSpinnerLoader(false);
      },
      (err) => {
        this.isAnyError = true;
        if (err.status && err.status == 500) {
          this.errorMessage = "Something went wrong. Please try again later.";
        } else {
          this.errorMessage = err.error.message;
        }
        classRef.globalService.toggleSpinnerLoader(false);
      }
    );
  }

  private validateLoginUserData(userId, password) {
    if (userId == undefined || userId == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter User Id";
      document.getElementById("userId").focus();
      return false;
    } /*else if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userId)){
			this.isAnyError=true;
			this.errorMessage="Please enter a valid email address.";
			return false;
		}*/

    if (password == undefined || password == "") {
      this.isAnyError = true;
      this.errorMessage = "Password field cannot be blank";
      document.getElementById("password").focus();
      return false;
    } else if (password.length < 8) {
      this.isAnyError = true;
      this.errorMessage = "Enter atleast 8 characters for your password";
      document.getElementById("password").focus();
      return false;
    }

    return true;
  }
  
  showPassword(show:boolean){
    console.log('hol')
    if(show){
      this.passwordInputType='text';
    }else{
      this.passwordInputType='password';
    }
  }
}
