import { Component } from '@angular/core';
import { navItems, navItemsAdmin, navItemsAccountManager, navItemsMithiAdmin } from '../../_nav';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { RBAC } from '../../core/enums/GlobalEnums';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SyncStorageManager } from '../../core/database/SyncStorageManager';
import { environment } from '../../../environments/environment';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-dashboard',
  styleUrls: ['./default-layout.component.scss'],
  templateUrl: './default-layout.component-2.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = true;
  public navItems = navItems;
  public navItemsAdmin = navItemsAdmin;
  public navItemsAccountManager = navItemsAccountManager;
  public navItemsMithiAdmin = navItemsMithiAdmin;
  user: any;
  isWideScreen: boolean = false;

  constructor(
    public globalService: GlobalService,
    private router: Router,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private syncStorageManager: SyncStorageManager) {
    this.globalService = globalService;
    this.router = router;
    this.user = this.globalService.user;

    if (this.globalService.user != undefined) {
      this.globalService.setCurrentUserRole(this.globalService.user);
      this.globalService.getisUserImpersonated();
    }
    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 991px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        // hide stuff      
        this.sidebarMinimized = true;
        this.isWideScreen = false;
      } else {
        // show stuff
        this.sidebarMinimized = false;
        this.isWideScreen = true;
      }
    });


    if(this.syncStorageManager.getLocalProperty("toggleSidebarState")) {
       this.toggleMinimize();
    }
    this.globalService.portalLogo = "assets/img/brand/" + this.globalService.tenant + "-logo.png";
    this.globalService.portalLogoFavicon = "assets/img/brand/" + this.globalService.tenant + "-favicon.png";
  }

  toggleMinimize() {
    this.sidebarMinimized = !this.sidebarMinimized;
    this.syncStorageManager.setLocalProperty("toggleSidebarState", this.sidebarMinimized);
  }

  expandMenu(menu) {
    menu['expand'] = !menu['expand'];
  }

  navigateToDashboard() {
    this.router.navigate(["dashboard"]);
  }

  closeSideBar() {
    if (!this.isWideScreen)
      this.sidebarMinimized = true;
  }

  logout() {
    var classRef = this;
    var payload = {};
    classRef.globalService.toggleSpinnerLoader(true);
    var url = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["logout"];

    this.userService.post(url, payload).then(function (response:any) {;
      classRef.globalService.toggleSpinnerLoader(false);
      console.log("Logging out, please wait!!");
      classRef.globalService.removeUserDetails();
      classRef.router.navigate(['login']);
    }, function (error) {
      classRef.globalService.toggleSpinnerLoader(false);
    });
  }

  viewMyProfile() {
    var userId = this.user.id;
    var companyName = this.user.companyName;

    if (userId != undefined) {
      if (RBAC.ROLE_PARTNER != this.globalService.userRole) {
        this.router.navigateByUrl('users/view', { state: { id: userId, companyName: companyName, isMyProfile: true } });
      } else {
        this.router.navigateByUrl('partner/view', { state: { id: userId, companyName: companyName } });
      }
    }
  }
}
