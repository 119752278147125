import { Component, OnInit, ViewChild } from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  email: any;
  userMessage: any;
  isAnyError: any;
  errorMessage: any;
  public http: any;

  @ViewChild('infoModal') public infoModal: ModalDirective;

  constructor(public globalService: GlobalService, private router: Router) {
    this.http = this.globalService.getHttpClientRef();
    this.router = router;
  }

  ngOnInit(): void {
    document.getElementById("email").focus();
  }

  register() {
    this.router.navigate(['register']);
  }

  login() {
    this.router.navigate(['login']);
  }

  forgotPassword(email) {
    var classRef = this;

    this.isAnyError = false;
    this.errorMessage = "";

    if (!this.validateUserData(email.value)) {
      return;
    }

    var serviceUrl = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["forgotPassword"];

    let payload = {
      "email": email.value
    }

    classRef.globalService.toggleSpinnerLoader(true);
    this.http.post(serviceUrl, payload, { observe: 'response' }).subscribe(
      (response) => {
        classRef.userMessage = response.body.message;
        document.getElementsByTagName('form')[0].reset();
        this.infoModal.show();
        classRef.globalService.toggleSpinnerLoader(false);
      },
      (err) => {
        this.isAnyError = true;
        classRef.globalService.toggleSpinnerLoader(false);
        document.getElementById("email").focus();
        if (err.status && err.status == 500) {
          this.errorMessage = "Something went wrong. Please try again later";
        } else {
          this.errorMessage = err.error.message;
        }
      }
    );
  }

  private validateUserData(email) {
    if (email == undefined || email == "") {
      this.isAnyError = true;
      this.errorMessage = "Enter email id";
      document.getElementById("email").focus();
      return false;
    } else if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)){
			this.isAnyError=true;
			this.errorMessage="Enter valid email address";
      document.getElementById("email").focus();
			return false;
		}

    return true;
  }
}
