<div class="landing-page">
  <!-- <div class="fixed-top"> -->
  <nav class="navbar  header-width">
    <button class="border-0 menu-btn" (click)="toggleMinimize()"><img src="assets/icons/hamburger-icon.svg"
        alt="Menu Icon"></button>
    <div class="d-none d-md-flex px-3">
      <img src="assets/icons/hi.svg" alt="Hi">
      <span id="hdr_user_name" *ngIf="user != undefined" class="welcome px-2">Welcome Back {{user.firstName |
        titlecase}}</span>

    </div>
    <div id="end-impersonation" *ngIf="globalService.isUserImpersonated">
      You have impersonated User
      <button type="button" class="btn btn-warning" title="End Impersonation"
        (click)="globalService.endImpersonatingUser()">
        <i class="nav-icon icon-user"></i> End User Impersonation
      </button>
    </div>



    <ul class="nav navbar-nav ml-auto">
      <!-- <li class="nav-item timezone_header">Timezone (UTC)</li> -->
      <li class="nav-item dropdown" dropdown placement="bottom right">
        <a class="nav-link nav-dropdown-toggle d-flex" data-toggle="dropdown" href="#" role="button"
          aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
          <span><img src="assets/icons/person.svg" alt="person"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">

          <a class="dropdown-item" (click)="viewMyProfile();"><i class="fa fa-user"></i> My Profile</a>
          <a class="dropdown-item" [routerLink]="['/user/change-password']"><i class="fa fa-user"></i> Change
            Password</a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="logout()"><i class="fa fa-lock"></i> Logout</a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- </div> -->
  <div class="d-flex py-2 px-0 col-12 content">
    <div id="wrapper" [ngClass]="sidebarMinimized ? 'minimized-sidebar hide-sideBar':''">

      <div class="sidebar-wrapper ">

        <div class=" d-flex align-items-center justify-content-center cursor-pointer">
          <img src="{{globalService.portalLogo}}"  alt="{{globalService.tenant + ' logo'}}" class="py-4" *ngIf="!sidebarMinimized" (click)="navigateToDashboard()" style="width: 100px;">
          <img src="{{globalService.portalLogoFavicon}}" alt="{{globalService.tenant + ' logo'}}" *ngIf="sidebarMinimized" class="py-4" (click)="navigateToDashboard()">
        </div>
        <div class="overflow-auto h-75">
          <ng-container *ngIf="globalService.userRole == 2 && globalService.tenant != 'mithiaffiliates'">
            <ng-container *ngTemplateOutlet="routing; context: {$implicit: navItemsAdmin}"></ng-container>
          </ng-container>
          
          <ng-container *ngIf="globalService.userRole == 2 && globalService.tenant == 'mithiaffiliates'">
            <ng-container *ngTemplateOutlet="routing; context: {$implicit: navItemsMithiAdmin}"></ng-container>
          </ng-container>

          <ng-container *ngIf="globalService.userRole == 3">
            <ng-container *ngTemplateOutlet="routing; context: {$implicit: navItems }"></ng-container>
          </ng-container>
          <ng-container *ngIf="globalService.userRole == 4">
            <ng-container *ngTemplateOutlet="routing; context: {$implicit: navItemsAccountManager }"></ng-container>
          </ng-container>
        </div>
        <div class="side-nav-open px-2 py-1" *ngIf="sidebarMinimized">
          <img src="assets/icons/arrow.svg" alt="open side navigation" class="p-3"
            (click)="toggleMinimize($event)" matTooltip="Expand" [matTooltipPosition]="'after'">
        </div>

        
        <div class="pt-4 pr-3 d-flex align-items-end justify-content-end side-nav-collapse"  *ngIf="!sidebarMinimized">
            <img src="assets/icons/arrow.svg" alt="close side navigation" class="p-3"
            (click)="toggleMinimize($event)" matTooltip="Expand" [matTooltipPosition]="'after'">
        </div>

      </div>
    </div>
    <div class="main  pr-0" [ngClass]="sidebarMinimized ? 'minimized-sidebar-main ':'hide-content'">

      <cui-breadcrumb>
      </cui-breadcrumb>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
      <footer class="footer d-flex justify-content-end pb-2 pr-0 pr-md-5">
        <span class="pr-2 pr-md-4"><a href="https://www.revmapper.com" target="_blank">RevMapper</a> &copy; 2023.</span>
        <span class="pr-3 pr-md-5">Powered by <a href="https://www.revmapper.com" target="_blank">RevMapper</a></span>
      </footer>
    </div>
  </div>




</div>


<ng-template #routing let-items>
  <div *ngFor="let item of items" class=" d-flex flex-column align-items-center px-2 py-1 ">
    <ng-container *ngIf="item.children">
      <div class="d-flex menu-item p-2 align-items-center justify-content-between" (click)="expandMenu(item)"
        matTooltip="{{sidebarMinimized?item.name:''}}" [matTooltipPosition]="'after'">
        <div class="d-flex">
          <!-- <img src="{{item.icon2}}" alt="{{item.name}}" class="pl-2  icon" [ngClass]="sidebarMinimized ? '':'pr-3' "> -->
          <i class="nav-icon {{item.icon}}"></i>
          <span *ngIf="!sidebarMinimized" class="pr-5">{{item.name}}</span>
        </div>
        <img src="assets/icons/arrow.svg" alt="drop down" [ngClass]="!item.expand ? 'arrow-icon':'arrow-transition' ">
      </div>
      <div *ngIf="item.expand" class="d-flex flex-column">
        <a *ngFor="let childItem of item.children" class="d-flex menu-item p-2 mt-2 align-items-center"
          [routerLink]="childItem.url" routerLinkActive="active" (click)="closeSideBar()"
          matTooltip="{{sidebarMinimized?childItem.name:''}}" [matTooltipPosition]="'after'">
          <!-- <img src="{{childItem.icon2}}" alt="{{childItem.name}}" class="pl-2  icon"
            [ngClass]="sidebarMinimized ? 'pr-2':'pr-3' "> -->
            <i class="nav-icon {{childItem.icon}}"></i>
          <span class="pr-5" *ngIf="!sidebarMinimized">{{childItem.name}}</span>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!item.children">
      <a class="d-flex menu-item p-2 align-items-center" [ngClass]="sidebarMinimized ? 'justify-content-center':'' "
        [routerLink]="item.url" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
        (click)="closeSideBar()" matTooltip="{{sidebarMinimized?item.name:''}}" [matTooltipPosition]="'after'">
        <!-- <img src="{{item.icon2}}" alt="{{item.name}}" class="pl-2 icon" [ngClass]="sidebarMinimized ? 'pr-2':'pr-3' "> -->
        <i class="nav-icon {{item.icon}}"></i>
        <span *ngIf="!sidebarMinimized" class="pr-5">{{item.name}}</span>
      </a>
    </ng-container>

  </div>
</ng-template>

<div id="spinner_loader">
  <div class="loader"></div>
</div>