<div class="app-body prelogin">
    <main class="main d-flex align-items-center">
      <div class="container">
        <div class="company-brand-logo">
          <img src="assets/img/brand/company-logo.png" alt="RevMapper" />
        </div>
        <div class="row">
          <div class="col-md-8 mx-auto">
            <div class="card-group">
              <div class="card p-4">
                <div class="card-body">
                  <form>
                    <h1>Reset Password</h1>
                    <div class="form-group">
                        <label for="inputNewPassword">New Password</label>
                        <input type="password" #inputNewPassword name="inputNewPassword" id="inputNewPassword" class="form-control" placeholder="New Password" required />
                    </div>

                    <div class="form-group">
                        <label for="inputConfirmNewPassword">Confirm New Password</label>
                        <input type="password" #inputConfirmNewPassword name="inputConfirmNewPassword" id="inputConfirmNewPassword" class="form-control" placeholder="Confirm New Password" required />
                    </div>
                    
                    <p class="hint"><strong>Hint:</strong> <br />Password must be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.</p>


                      <div class="row">
                        <p><span *ngIf="isAnyError" class='error'> {{errorMessage}} </span></p>
                      </div>
                      <div class="submit-btn">
                          <button type="button" class="btn btn-primary" (click)="onResetPassword(inputNewPassword, inputConfirmNewPassword)">SAVE</button>
                      </div>
                  </form>
                </div>
              </div>
              <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                <div class="card-body text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Onboard with us and explore the features.</p>
                    <button type="button" class="btn btn-primary active mt-3" id="register-btn" (click)="register()">Register Now!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  
  <div id="change-password-message-modal" bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="changePasswordModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-info" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close" (click)="infoModal.hide()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p *ngIf="this.userMessage != undefined">{{this.userMessage}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="infoModal.hide();login();">Okay</button>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->

  <app-footer>
    <span><a href="https://www.revmapper.com" target="_blank">RevMapper</a> &copy; 2023.</span>
    <span class="ml-auto">Powered by <a href="https://www.revmapper.com" target="_blank">RevMapper</a></span>
  </app-footer>

  <div id="spinner_loader">
    <div class="loader"></div>
  </div>
  