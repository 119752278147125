import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { SyncStorageManager } from '../core/database/SyncStorageManager';
import { RBAC } from '../core/enums/GlobalEnums';
import { environment } from '../../environments/environment';
declare var jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  user: any;
  isAnyError: boolean;
  errorMessage: string;
  public showLoader: boolean = false;
  public portalLogo:string = "";
  public portalLogoFavicon: string = "";
  userRole: any;
  public showLandingPageList: boolean = false;
  public showCreateLandingPage: boolean = false;
  public showUpdateLandingPage: boolean = false;

  public showCampaignResourceList: boolean = false;
  public showCreateCampaignResource: boolean = false;
  public showUpdateCampaignResource: boolean = false;

  public showTrackingDomains: boolean = false;

  public showRegistrationPostback: boolean = false;
  public showOptimizationTool: boolean = false;

  public showEmailTemplates: boolean = false;
  public showSearchEmailTemplates: boolean = false;
  public showCreateEmailTemplates: boolean = false;
  public showUpdateEmailTemplates: boolean = false;

  public showTermsAndConditions: boolean = false;
  public showSearchTermsAndConditions: boolean = false;
  public showCreateTermsAndConditions: boolean = false;
  public showUpdateTermsAndConditions: boolean = false;

  public showReferralReporting: boolean = false;
  public showReferralURLReporting: boolean = false;
  public showAccountReporting: boolean = false;
  public showLandingPageReporting: boolean = false;
  public showPostbackReporting: boolean = false;
  public showPlayerReporting: boolean = false;
  public showPartnerReporting: boolean = false;
  public showPartnerSiteIdReporting: boolean = false;
  public showUpdatePartner: boolean = false;
  public showCountryReport: boolean = false;

  public showPartnerSiteId: boolean = false;
  public showSearchPartnerSiteId: boolean = false;
  public showCreatePartnerSiteId: boolean = false;
  public showUpdatePartnerSiteId: boolean = false;

  public showPartnerDeals: boolean = false;
  public showSearchPartnerDeals: boolean = false;
  public showViewPartnerDetailDeals: boolean = false;

  public showPartnerPostback: boolean = false;
  public showSearchPartnerPostback: boolean = false;
  public showCreatePartnerPostback: boolean = false;
  public showUpdatePartnerPostback: boolean = false;
  
  public showPartnerApiKey: boolean = false;

  public showNotifications: boolean = false;
  public showSearchNotifications: boolean = false;
  public showCreateNotifications: boolean = false;
  public showUpdateNotifications: boolean = false;

  public showPaymentSettings: boolean = false;
  public showDefaultSettings: boolean = false;
  public showSearchByUser: boolean = false;
  public showUpdateUser: boolean = false;

  public IMPERSONATED_USER = "impersonatedUser";
  public IS_USER_IMPERSONATED = "isUserImpersonated";
  public userImpersonated: any;
  public isUserImpersonated: boolean = false;

  public showAutoProcessingConfig: boolean = false;
  public showProcessingConfig: boolean = false;
  public showProcessingSnapshot: boolean = false;
  public showProcessingSnapshotForSKC: boolean = false;
  public showProcessingSnapshotForWMS: boolean = false;
  public showProcessingSnapshotData: boolean = false;
  public processingSnapshot: any = undefined;
  public processingSnapshotApi: any = undefined;
  public processingId: any = undefined;
  public processingPlayerId: any = undefined;
  public processingBrandName: any = undefined;
  public tenant: any;

  public currentTrackingTag = "progressPlay";

  public digitsPercentageRegex = /^\d{1,9}(\.\d{1,2})?%?$/g;
  public digitsRegex = /^\d{1,9}(\.\d{1,2})?$/g;

  constructor(
    public http: HttpClient,
    public router: Router,
    private syncStorageManager: SyncStorageManager) {

    if (this.getUserDetails() != undefined) {
      this.user = this.getUserDetails();
    }
    
    var hostname = window.location.hostname;
    this.tenant = hostname.split('.')[0];
  }

  public getHttpClientRef() {
    return this.http
  }



  public getRouterRef() {
    return this.router;
  }

  public getUserAuthToken() {
    var authToken = this.syncStorageManager.getLocalProperty("authToken");
    return authToken;
  }

  public setUserAuthToken(authToken) {
    this.syncStorageManager.setLocalProperty("authorizationToken", authToken);
  }

  public setUserDetails(user) {
    this.user = user;
    window.localStorage.removeItem("userDetails");
    this.syncStorageManager.setLocalProperty("userDetails", user);
  }
  
  public setTenant(tenant) {
    this.syncStorageManager.setLocalProperty("tenant", tenant);
  }

  public getTenant() {
    var tenant = this.syncStorageManager.getLocalProperty("tenant");
    return tenant;
  }


  public getUserDetails() {
    var user = this.syncStorageManager.getSessionProperty(this.IMPERSONATED_USER);
    if (user != null) {
      var isUserImpersonated = this.syncStorageManager.getSessionProperty(this.IS_USER_IMPERSONATED);
      if (isUserImpersonated != null) {
        this.setisUserImpersonated(isUserImpersonated);
      }
      return user;
    } else if (this.syncStorageManager.getLocalProperty("userDetails") != "undefined") {
      return this.syncStorageManager.getLocalProperty("userDetails");
    } else {
      this.logout();
    }
    /*if(window.sessionStorage.getItem("impersonatedUser") != null) {
      if(window.sessionStorage.getItem("isUserImpersonated") != null) {
        this.setisUserImpersonated(true);
      }
      return JSON.parse(window.sessionStorage.getItem("impersonatedUser")); 
    } else if(window.localStorage.getItem("userDetails") != "undefined") {
      return JSON.parse(window.localStorage.getItem("userDetails")); 
    } else {
      this.logout();
    }*/
  }

  public removeUserDetails() {
    this.user = undefined;
    window.localStorage.removeItem("lastlogin");
    window.localStorage.removeItem("userDetails");
    window.localStorage.removeItem("currentCampaignDetails");
    window.localStorage.removeItem("currentBrandDetails");
    window.localStorage.removeItem("authorizationToken");
    window.localStorage.removeItem("toggleSidebarState");
    window.localStorage.removeItem("tenant");
  }

  public setCurrentUserRole(user) {
    this.userRole = user.role;
  }

  public logout() {
    console.log("Logging out, please wait!!");

    this.setisUserImpersonated(false);
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

  public create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }

  public truncateWithEllipses(text, maxLength) {
    return text.substr(0, maxLength - 1) + (text.length > maxLength ? '...' : '');
  }

  toggleEllipsis() {
    // Configure/customize these variables.
    var showChar = 200;  // How many characters are shown by default
    var ellipsestext = "...";
    var moretext = "Read More >>";
    var lesstext = "<< Read less";


    jQuery('.more').each(function () {
      var content = jQuery(this).html();

      if (content.length > showChar) {

        var c = content.substr(0, showChar);
        var h = content.substr(showChar, content.length - showChar);

        var html = c + '<span class="moreellipses">' + ellipsestext + '</span><span class="morecontent"><span>' + h + '</span><a href="" class="morelink">' + moretext + '</a></span>';

        jQuery(this).html(html);
      }

    });

    jQuery(".morelink").click(function () {
      if (jQuery(this).hasClass("less")) {
        jQuery(this).removeClass("less");
        jQuery(this).html(moretext);
      } else {
        jQuery(this).addClass("less");
        jQuery(this).html(lesstext);
      }
      jQuery(this).parent().prev().toggle();
      jQuery(this).prev().toggle();
      return false;
    });
  }

  //zero padding if number is less than 10
  public zeroPadding(number) {
    if (number < 10) {
      number = '0' + '' + number;
    } else {
      number = number;
    }
    return number;
  }

  public toggleSpinnerLoader(state) {
    if (state) {
      document.getElementById("spinner_loader").style.display = "block";
    } else {
      document.getElementById("spinner_loader").style.display = "none";
    }
  }



  searchItem(searchElem, containerElem, rowElem, searchBy) {
    var input, filter, parentElement, children, cardHeader, i, txtValue;
    input = document.getElementById(searchElem);
    filter = input.value.toUpperCase();
    parentElement = document.getElementById(containerElem);
    children = parentElement.getElementsByTagName(rowElem);

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < children.length; i++) {
      cardHeader = children[i].getElementsByClassName(searchBy)[0];
      if (cardHeader != undefined) {
        txtValue = cardHeader.textContent || cardHeader.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          children[i].style.display = "";
        } else {
          children[i].style.display = "none";
        }
      }
    }
  }

  onCopyLink(event, elem, link) {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.value = link;
    jQuery(elem).select();
    document.execCommand("copy");
    document.body.removeChild(el);

    event.target.innerText = "Copied";
    setTimeout(() => {
      event.target.innerText = "Copy Link";
    }, 3000);
  }

  getFormattedCountryList(countryString) {
    var countryList = JSON.parse(countryString);
    var list = "";
    var string = countryList.map((item, index) => {
      if (index != 0)
        list += ", ";
      list += item.country;
    });
    return list;
  }

  validateDigitsPercentage(data) {
    if (data != undefined) {
      if (data.value.match(this.digitsPercentageRegex) == null) {
        data.value = data.value.replace(/[^0-9\.]/g, '');
      }
      console.log(data.value);
    }
  }

  validateDigits(data) {
    if (data != undefined) {
      if (data.value.match(this.digitsRegex) == null) {
        data.value = data.value.replace(/[^0-9\.]/g, '');
      }
      console.log(data.value);
    }
  }

  todaysDate(elemId) {
    var today: any;

    today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    today = yyyy + '-' + mm + '-' + dd;
    document.getElementById(elemId).setAttribute("max", today);
    document.getElementById(elemId).setAttribute("value", today);
  }

  getDesiredDate(myDate) {
    var myVariable = myDate;
    var makeDate = new Date(myVariable);

    console.log('Original date: ', makeDate.toString());

    makeDate.setMonth(makeDate.getMonth() - 1);

    console.log('After subtracting a month: ', makeDate.toString());
  }

  checkUserRoleToDecideAction() {
    if (RBAC.ROLE_PARTNER == this.userRole) {
      this.router.navigate(['/dashboard']);
    }
  }

  checkUserAdminRoleToDecideAction() {
    if (RBAC.ROLE_ADMIN != this.userRole) {
      this.router.navigate(['/dashboard']);
    }
  }

  getUserImpersonated() {
    var userImpersonated = this.syncStorageManager.getSessionProperty(this.IMPERSONATED_USER);
    if (userImpersonated != null) {
      return userImpersonated;
    }
    return this.userImpersonated;
  }

  setUserImpersonated(userImpersonated) {
    this.syncStorageManager.setSessionProperty(this.IMPERSONATED_USER, userImpersonated);
    this.userImpersonated = userImpersonated;
  }

  getisUserImpersonated() {
    var isUserImpersonated = this.syncStorageManager.getSessionProperty(this.IS_USER_IMPERSONATED);
    if (isUserImpersonated != null) {
      return isUserImpersonated;
    }
    return this.isUserImpersonated;
  }

  setisUserImpersonated(isUserImpersonated) {
    this.syncStorageManager.setSessionProperty(this.IS_USER_IMPERSONATED, isUserImpersonated);
    this.isUserImpersonated = isUserImpersonated;
  }


  startImpersonatingUser(event) {
    var el = event.currentTarget.parentElement;
    var partnerId = el.dataset.partnerId;
    console.log("Partner Id:: " + partnerId);
    this.toggleSpinnerLoader(true);
    this.getImpersonatingUserDetails(partnerId);
  }

  getImpersonatingUserDetails(partnerId) {
    var classRef = this;
    var url = environment.apiEndpoint[environment.environmentsNames[environment.baseUrl]]["url"] + environment.apiUrls["baseUser"] + "/" + partnerId;

    this.get(url).then(function (response: any) {
      classRef.setisUserImpersonated(true);
      classRef.setUserImpersonated(response.data);
      location.reload();
    }, function (error) {
      classRef.isAnyError = true;
      classRef.toggleSpinnerLoader(false);
      if (error.status && error.status == 401) {
        classRef.logout();
      } else if (error.status && error.status == 500) {
        classRef.errorMessage = "Something went wrong. Please try again later.";
      } else {
        classRef.errorMessage = error.error.message;
      }
    });
  }

  endImpersonatingUser() {
    var classRef = this;
    console.log("Ending User Impersonating:: ");
    this.toggleSpinnerLoader(true);
    this.setisUserImpersonated(false);
    sessionStorage.clear();
    location.reload();
    window.location.href = "/dashboard";
  }

  get(url) {
    var classRef = this;

    return new Promise(function (resolve, reject) {

      classRef.http.get(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  post(url, payload) {
    var classRef = this;

    return new Promise(function (resolve, reject) {

      classRef.http.post(url, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  put(url, payload) {
    var classRef = this;

    return new Promise(function (resolve, reject) {

      classRef.http.put(url, payload).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  delete(url) {
    var classRef = this;

    return new Promise(function (resolve, reject) {

      classRef.http.delete(url).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
