import { INavData } from '@coreui/angular';

export const navItems: any[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    icon2: 'assets/icons/dashboard2.svg',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },

  {
    name: 'My Profile',
    url: '/partner/view',
    icon: 'icon-briefcase',
    icon2: 'assets/icons/person.svg',
  },

  {
    name: 'Promotional Kit',
    url: '/business',
    icon: 'icon-globe',
    icon2: 'assets/icons/business.svg',
    children: [
      {
        name: 'Brands',
        url: '/brands',
        icon: 'fa fa-diamond',
        icon2: 'assets/icons/brand2.svg',
      }
    ]
  },

  {
    name: 'Finance',
    url: '/finance',
    icon: 'icon-briefcase',
    icon2: 'assets/icons/finance.svg',
  },

  {
    name: 'Reporting',
    url: '/reporting',
    icon: 'icon-book-open',
    icon2: 'assets/icons/reporting.svg',
  }
];


export const navItemsAccountManager: any[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    icon2: 'assets/icons/dashboard2.svg',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },

  {
    name: 'Search',
    url: '/search',
    icon: 'icon-magnifier',
    icon2: 'assets/icons/search2.svg',
  },

  {
    name: 'Manage Accounts',
    url: '/accounts',
    icon: 'icon-user',
    icon2: 'assets/icons/person.svg',
    children: [
      {
        name: 'Partner',
        url: '/partner',
        icon: 'fa fa-users',
        icon2: 'assets/icons/partner2.svg',
      }
    ]
  },

  {
    name: 'Business Setup',
    url: '/business',
    icon: 'icon-globe',
    icon2: 'assets/icons/business.svg',
    children: [
      {
        name: 'Brands',
        url: '/brands',
        icon: 'fa fa-diamond',
        icon2: 'assets/icons/brand2.svg',
      },
      {
        name: 'Deals',
        url: '/deals',
        icon: 'fa fa-handshake-o',
        icon2: 'assets/icons/deal2.svg',
      }
    ]
  },

  {
    name: 'Configuration',
    url: '/configuration',
    icon: 'icon-settings',
    icon2: 'assets/icons/configuration.svg',
  },

  {
    name: 'Finance',
    url: '/finance',
    icon: 'icon-briefcase',
    icon2: 'assets/icons/finance.svg',
  },

  {
    name: 'Reporting',
    url: '/reporting',
    icon: 'icon-book-open',
    icon2: 'assets/icons/reporting.svg',
  }
];

export const navItemsAdmin: any[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    icon2: 'assets/icons/dashboard2.svg',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },

  {
    name: 'Search',
    url: '/search',
    icon: 'icon-magnifier',
    icon2: 'assets/icons/search2.svg',
  },

  {
    name: 'Manage Accounts',
    url: '/accounts',
    icon: 'icon-user',
    icon2: 'assets/icons/person.svg',
    children: [
      {
        name: 'Partner',
        url: '/partner',
        icon: 'fa fa-users',
        icon2: 'assets/icons/partner2.svg',
      },
      {
        name: 'User',
        url: '/users',
        icon: 'fa fa-user',
        icon2: 'assets/icons/partner3.svg',
      }
    ]
  },

  {
    name: 'Business Setup',
    url: '/business',
    icon: 'icon-globe',
    icon2: 'assets/icons/business.svg',
    children: [
      {
        name: 'Brands',
        url: '/brands',
        icon: 'fa fa-diamond',
        icon2: 'assets/icons/brand2.svg',
      },
      {
        name: 'Deals',
        url: '/deals',
        icon: 'fa fa-handshake-o',
        icon2: 'assets/icons/deal2.svg',
      }
    ]
  },

  {
    name: 'Configuration',
    url: '/configuration',
    icon: 'icon-settings',
    icon2: 'assets/icons/configuration.svg',
  },

  {
    name: 'Finance',
    url: '/finance',
    icon: 'icon-briefcase',
    icon2: 'assets/icons/finance.svg',
  },

  {
    name: 'Reporting',
    url: '/reporting',
    icon: 'icon-book-open',
    icon2: 'assets/icons/reporting.svg',
  }
];


export const navItemsMithiAdmin: any[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    icon2: 'assets/icons/dashboard2.svg',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },

  {
    name: 'Search',
    url: '/search',
    icon: 'icon-magnifier',
    icon2: 'assets/icons/search2.svg',
  },

  {
    name: 'Manage Accounts',
    url: '/accounts',
    icon: 'icon-user',
    icon2: 'assets/icons/person.svg',
    children: [
      {
        name: 'Partner',
        url: '/partner',
        icon: 'fa fa-users',
        icon2: 'assets/icons/partner2.svg',
      },
      {
        name: 'User',
        url: '/users',
        icon: 'fa fa-user',
        icon2: 'assets/icons/partner3.svg',
      }
    ]
  },

  {
    name: 'Business Setup',
    url: '/business',
    icon: 'icon-globe',
    icon2: 'assets/icons/business.svg',
    children: [
      {
        name: 'Brands',
        url: '/brands',
        icon: 'fa fa-diamond',
        icon2: 'assets/icons/brand2.svg',
      },
      {
        name: 'Deals',
        url: '/deals',
        icon: 'fa fa-handshake-o',
        icon2: 'assets/icons/deal2.svg',
      }
    ]
  },

  {
    name: 'Configuration',
    url: '/configuration',
    icon: 'icon-settings',
    icon2: 'assets/icons/configuration.svg',
  },

  {
    name: 'Finance',
    url: '/finance',
    icon: 'icon-briefcase',
    icon2: 'assets/icons/finance.svg',
  },

  {
    name: 'Reporting',
    url: '/reporting',
    icon: 'icon-book-open',
    icon2: 'assets/icons/reporting.svg',
  },

  {
    name: 'Processing',
    url: '/processing',
    icon: 'icon-globe',
    icon2: 'assets/icons/business.svg',
  }
];