<div class="app-body lr-page d-flex flex-column pt-lg-0 pt-4" id="register">
  <main class="main d-flex flex-column justify-content-around">
    <div class="d-flex col-12 justify-content-center  pb-0 pb-md-3 py-lg-4 logo-container">
      <img src="{{this.globalService.portalLogo}}" alt="{{globalService.tenant + ' logo'}}" class="logo" style="width: 100px;"/>
    </div>
    <div class="container-fluid h-100 px-0">
      <div class="d-flex align-items-center flex-column flex-md-row justify-content-center">
        <div class="col-md-6 mx-auto effect6">
          <div class="card p-0 p-md-4">
            <div class="card-body px-0 " id="card-body">
              <form>
                <h1 class="pt-4 pt-md-0 mb-0">Register</h1>
                <p class="login-heading text-center py-3 mb-0">Create your account</p>

                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" #firstname id="firstname" maxlength="20" class="form-control"
                        placeholder="First Name" autocomplete="firstname"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" #lastname id="lastname" maxlength="20" class="form-control"
                        placeholder="Last Name" autocomplete="lastname"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-lg-6">

                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" #userid id="userid" maxlength="20" class="form-control" placeholder="User Id"
                        autocomplete="userid"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">@</span>
                      </div>
                      <input type="text" #email id="email" class="form-control" placeholder="Email" autocomplete="email"
                        (keyup)="validateEmail(email)"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>

                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-lg-6 d-flex">
                    <div class="p-2 password-info"><i class="fa fa-solid fa-info-circle" [matTooltip]="passwordInfo"
                        [matTooltipPosition]="'left'"></i></div>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                      <input [type]="passwordInputType" #password id="password" class="form-control"
                        placeholder="Password" autocomplete="new-password" (keyup)="validatePassword(password)"
                        required />
                      <div class="input-group-append">
                        <span class="input-group-text" (click)="showPassword(false)"
                          *ngIf="passwordInputType ==  'text'">
                          <i class="fa fa-solid fa-eye"></i>
                        </span>
                        <span class="input-group-text" (click)="showPassword(true)"
                          *ngIf="passwordInputType ==  'password'">
                          <i class="fa fa-solid fa-eye-slash"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-lg-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                      <input type="text" #companyName id="companyName" class="form-control" placeholder="Company Name"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>
                  </div>
                </div>


                <div class="form-row">
                  <div class="form-group col-lg-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-user"></i></span>
                      </div>
                      <input type="text" #websiteurl id="websiteurl" class="form-control" placeholder="Website Url"
                        autocomplete="websiteurl"
                        (keyup.enter)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)"
                        required />
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <div class="form-check">
                    <input class="form-check-input" #invalidCheck type="checkbox" value="" id="invalidCheck" required />
                    <label class="form-check-label" style="padding-left: 5px;">
                      I accept the <u (click)="getTermsAndConditions();" style="cursor: pointer;">Terms & Conditions</u>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <small><span *ngIf="isAnyError" class='error'> {{errorMessage}} </span></small>
                </div>

                <button type="button" class="btn btn-block btn-success" id="submit-btn"
                  (click)="register(firstname, lastname, userid, email, password, companyName, websiteurl, invalidCheck)">Create
                  Account</button>
                <button type="button" class="btn btn-block btn-link" id="register-btn"  (click)="login()">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="d-flex justify-content-around pt-3 ">
      <span><a href="https://www.revmapper.com" target="_blank">RevMapper</a> &copy; 2023.</span>
      <span class="">Powered by <a href="https://www.revmapper.com" target="_blank">RevMapper</a></span>
    </footer>
  </main>
</div>

<div id="register-message-modal" bsModal #infoModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="registerModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" (click)="infoModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="this.registerMessage != undefined">{{this.registerMessage.body.message}}</p>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="infoModal.hide();login();">Okay</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->




<div id="tc-message-modal" bsModal #dangerModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="tcModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close" (click)="dangerModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p *ngIf="this.termsAndConditionsList != undefined">{{this.termsAndConditionsList.tc}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="dangerModal.hide();">Okay</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->



<div id="spinner_loader">
  <div class="loader"></div>
</div>