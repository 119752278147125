<div class="app-body lr-page d-flex flex-column pt-lg-0 pt-4">
  <main class="main d-flex flex-column justify-content-around">
    <div class="d-flex col-12 col-lg-6 offset-lg-6 pb-3 py-lg-4 logo-container">
      <!-- <img src="assets/img/brand/company-logo.png" alt="RevMapper" class=" logo"/> -->
      <img class="logo" src="{{globalService.portalLogo}}" alt="{{globalService.tenant + ' logo'}}" />
    </div>
    <div class="container-fluid h-100">
     
      <div class="d-flex align-items-center flex-column flex-md-row justify-content-center people-bg">
        <div class="col-12 col-md-6  col-lg-5 effect5">
          <div class="card-group effect6 offset-0 offset-lg-3">
            <div class="card p-0 p-md-4">
              <div class="card-body px-0">
                <form>
                  <h1 class="pt-4 pt-md-0">Welcome Back !</h1>
                  <p class="login-heading text-center py-4">Login Here</p>
                  <div class="form-group mb-3">
                    <label for="userId">Email Address</label>
                    <input type="text" #userId name="userId" id="userId" class="form-control"
                      placeholder="User Id / Email Id" autocomplete="userid" (keyup.enter)="loginUser(userId,password)"
                      required>
                  </div>
                  <div>
                    <label for="password">Password</label>
                    <div class="input-group mb-0">

                      <input [type]="passwordInputType" #password name="password" id="password" class="form-control"
                        placeholder="Password" autocomplete="current-password"
                        (keyup.enter)="loginUser(userId,password)" required>
                      <div class="input-group-append">
                        <span class="input-group-text" (click)="showPassword(false)"
                          *ngIf="passwordInputType ==  'text'">
                          <i class="fa fa-solid fa-eye"></i>
                        </span>
                        <span class="input-group-text" (click)="showPassword(true)"
                          *ngIf="passwordInputType ==  'password'">
                          <i class="fa fa-solid fa-eye-slash"></i>
                        </span>
                      </div>

                    </div>
                    <div class="d-flex justify-content-end">
                      <small id="forgotpassword" class="form-text text-muted p-0" (click)="forgotpassword()">Forgot
                        password?</small>
                    </div>
                  
                  </div>

                  <div class="row py-3">
                    <small><span *ngIf="isAnyError" class='error'> {{errorMessage}} </span></small>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="button" class="btn btn-primary px-4 w-100" id="submit-btn"
                        (click)="loginUser(userId,password)">Login</button>
                    </div>
                  </div>
                  
                </form>
                <div class="col-12 text-center pt-3" >
                  <small id="register-text">Don't have an Account?</small>
                  <button type="button" class="btn btn-link" id="register-btn" (click)="register()">Sign Up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 d-none d-lg-flex"></div>
      </div>
    </div>
    <footer class="d-flex justify-content-around pt-5 mt-xl-5">
      <span><a href="https://www.revmapper.com" target="_blank">RevMapper</a> &copy; 2023.</span>
      <span class="">Powered by <a href="https://www.revmapper.com" target="_blank">RevMapper</a></span>
    </footer>
  </main>
 
</div>



<div id="spinner_loader">
  <div class="loader"></div>
</div>